var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Default slot" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSlot) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Provide optional content at the bottom of the time interface via the use of "
          )
        ]),
        _c("code", [_vm._v("default")]),
        _c("span", [
          _vm._v(
            " slot. The slot can be used to add buttons such as Now or Reset, etc."
          )
        ])
      ]),
      _c(
        "div",
        [
          _c(
            "b-time",
            {
              attrs: { "show-seconds": "", locale: "en" },
              model: {
                value: _vm.value,
                callback: function($$v) {
                  _vm.value = $$v
                },
                expression: "value"
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex", attrs: { dir: "ltr" } },
                [
                  _vm.value
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "outline-danger" },
                          on: { click: _vm.clearTime }
                        },
                        [_vm._v(" Clear time ")]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { size: "sm", variant: "outline-primary" },
                      on: { click: _vm.setNow }
                    },
                    [_vm._v(" Set Now ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }