var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Internationalization" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInternationalization) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("You can specify the locale (or locales) to use via the ")
        ]),
        _c("code", [_vm._v("locale")]),
        _c("span", [_vm._v(" prop. ")])
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-1", attrs: { cols: "12" } },
            [
              _c("label", { attrs: { for: "example-locales" } }, [
                _vm._v("Locale:")
              ]),
              _c("b-form-select", {
                attrs: { id: "example-locales", options: _vm.locales },
                model: {
                  value: _vm.locale,
                  callback: function($$v) {
                    _vm.locale = $$v
                  },
                  expression: "locale"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "auto" } },
            [
              _c(
                "b-time",
                _vm._b(
                  {
                    attrs: { locale: _vm.locale, "show-seconds": "" },
                    on: { context: _vm.onContext }
                  },
                  "b-time",
                  _vm.labels[_vm.locale] || {},
                  false
                )
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }